import React, { useEffect } from "react"
import { paths } from "@/paths"
import { useRouter } from "next/router"

const Home = () => {
    const router = useRouter()

    useEffect(() => {
        router.push(paths.login.go())
    }, [router])

    return <></>
}

export default Home
